import React, { Component } from 'react'
import { connect } from 'react-redux';
import moment from 'moment';
import { Container, Form, Row } from 'react-bootstrap';
import { HiLocationMarker, } from "react-icons/hi";
import { AiOutlinePlus } from "react-icons/ai";
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import { AC_GET_JOB, AC_SPINNER_START, AC_VIEW_APPLY_JOB } from '../../actions/jobAction';
import { Error, Success } from '../../common/swal';
import { FilevalidationNew, onErrorImage } from '../../common/validate';
import ImportedURL from '../../common/api';
import '../../assets/css/job_apply_form.css';
import Logo from './logo.png';
import Spinner from 'react-bootstrap/Spinner';
import { Link, Redirect } from 'react-router-dom';

const initialState = {
    resume: "",
    email: "",
    resumeError: "",
    redirect: false,
    spinner: false
}

class ApplyJob extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        const { params, path } = this.props.match;
        this.props.EnableSpinner()
        this.props.viewApplyJob(params.id);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }

    applyJob = value => {
        const { JobState } = this.props;
        const jobData = JobState.applyJobData;
        const data = this.state;
        if (data.email && data.resume && !this.state.emailValidError) {
            this.setState({ spinner: true })
            const formData = new FormData();
            formData.append('email', data.email);
            formData.append('resume', data.resume);
            formData.append('jobId', jobData.jobId);
            formData.append('hotel', jobData.hotelid);
            axios.post(ImportedURL.API.applyJob, formData)
                .then((res) => {
                    Success("Applied successfully");
                    this.setState({
                        resume: "",
                        email: "",
                        resumeError: "",
                        redirect: true,
                        spinner: false
                    })
                }).catch((err) => {
                    this.setState({ spinner: false })
                    if (err.response.status == 409) {
                        Error("Already applied for this job")
                    } else {
                        Error(err.response.statusMessage)
                    }
                })
        } else {
            Error("Please update email and resume");
        }
    }

    onChange = e => {
        const { name, value } = e.target;
        // const Error = name + "Error";
        if (name == 'resume') {
            this.setState({ resumeError: false });
            if (e.target.files.length > 0) {
                if (FilevalidationNew(e.target.files[0])) {
                    this.setState({ resume: e.target.files[0] });
                } else {
                    this.setState({ resumeError: true });
                }
            }
        } else {
            const regemail = /^\w+([/.-]?\w+)*@\w+([/.-]?\w+)*(\.\w{2,3})+$/;
            if (value) {
                if (regemail.test(value)) {
                    this.setState({ emailValidError: false })
                } else {
                    this.setState({ emailValidError: true });
                }
                this.setState({ [name]: value });
            } else {
                this.setState({ emailValidError: false });
                this.setState({ [name]: value });
            }
        }
    }

    render() {
        const { JobState } = this.props;
        const jobData = JobState.applyJobData;
        const spinner = JobState.spinner;
        let disable = false
        if (this.state.email === '' || this.state.resume === '' || this.state.emailValidError) {
            disable = true
        }
        if (this.state.redirect) return <Redirect to={'/'} />
        console.log("spinner",spinner);
        return (
            <>
                <div>
                    <section className='job_apply_form'>
                        <Container>
                            <div className='top_brand_log'>
                                <div className='brand_img_size'>
                                    <Link to='/'><img src={Logo} alt='' onError={onErrorImage} /></Link>
                                </div>
                            </div>
                            <div className='back_button'>
                                <Link to='/'><i className="fa fa-arrow-left mr-2" data-toggle="tooltip" title="fa fa-arrow-left"></i>Back</Link>
                            </div>
                            <div className='c_details_resume career'>
                                <Row>
                                    <div className='col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12'>
                                        <div className='left_detail'>
                                            <div className='title_loc'>
                                                <div className='logo_link mb-3'>
                                                    <div className='logo_size'>
                                                        <img src={ImportedURL.FILEURL + jobData.companyLogo} alt='' onError={onErrorImage} />
                                                    </div>
                                                </div>
                                                <h4 className='np mb-3'>{jobData.position ? jobData.position.name : ''}</h4>
                                                {jobData.hotel ? <div className='np mb-3'>
                                                    <span>Hotel Name : {jobData.hotel}</span><br></br>
                                                </div> : ""}
                                                {jobData.site ? <div className='np mb-3'>
                                                    <span>Hotel Site : <a href={jobData.site} className='site_link' target='_blank'>{jobData.site}</a></span><br></br>
                                                </div> : ""}
                                                <div className='np mb-3'>
                                                    <span><HiLocationMarker className='loc_i' />{jobData.address}</span><br></br>
                                                </div>
                                                {jobData.openings ? <div className='np mb-3'>
                                                    <span>No. of openings : {jobData.openings}</span><br></br>
                                                </div> : ""}
                                                <div className='np mb-3'>
                                                    <span>Experience : {jobData.experience}</span><br></br>
                                                </div>
                                                <div className='np mb-3'>
                                                    <span>Salary : {jobData.salary}</span><br></br>
                                                </div>
                                                <div className='np mb-3'>
                                                    <span>Posted On : {jobData.posedDate}</span><br></br>
                                                </div>
                                            </div>

                                            <div className='full_details career_description' dangerouslySetInnerHTML={{ __html: jobData.description }}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 '>
                                        <div className='resume_upp'>
                                            <h5>Upload Your Resume</h5>
                                            <div className='choose_file'>
                                                {this.state.resume ? <div>
                                                    <a href={URL.createObjectURL(this.state.resume)} className="btn btn-icon" target='_blank'><i class="fa fa-file mr-2" aria-hidden="true" title="Preview" ></i></a>
                                                </div> : ""}
                                                < div >
                                                    <label style={{ cursor: 'pointer' }}><i className={this.state.resume ? "fa fa-check mr-2" : "fa fa-upload mr-2"} aria-hidden="true"></i>{this.state.resume ? "Uploaded" : "Browse"}
                                                        <input type="file" size="60" name="resume" accept=".pdf,.doc,.docx" onChange={this.onChange} id="imageSet" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="invalid-feedback" style={{ display: this.state.resumeError ? "block" : 'none' }}>File format is not accepeted</div>
                                            <p>Accepted formats : pdf,doc,docx</p>
                                            <input type="email" class="form-control email_s" name='email' placeholder="Enter email" onChange={this.onChange} value={this.state.email} />
                                            <div class="invalid-feedback" style={{ display: this.state.emailValidError ? "block" : 'none' }}>Enter valid email</div>
                                            <p>By clicking the apply button i agree the get Hired terms of services</p>
                                            <button type='button' class="apply" style={disable ? { backgroundColor: '#8493c9', color: '#fff' } : {}} onClick={this.applyJob} disabled={disable}><i class="fa fa-check mr-2" aria-hidden="true"></i>Apply Now</button>
                                        </div>
                                    </div>
                                </Row>
                            </div>
                            <div className='view_all_jobs'>
                                <a ></a>
                            </div>
                            <div className='bottom_brand_log'>
                                <div className='brand_img_size'>
                                    <img src={Logo} alt='' />
                                </div>
                            </div>
                        </Container >
                    </section >
                    <div id="overlay" style={{ display: (spinner || this.state.spinner) ? 'block' : 'none' }}></div>
                    {
                        (spinner || this.state.spinner) ?
                            <div className='common_loader_ag_grid'>
                                <img className='loader_img_style_common_ag_grid' src='../../assets/images/careerlogo.png' onError={onErrorImage} />
                                <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                </Spinner>
                            </div>
                            : ""
                    }
                </div >
            </>
        )
    }
}
const mapStateToProps = state => ({
    HotelState: state.hotel,
    JobState: state.job
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ListHotel: AC_LIST_HOTEL, GetJob: AC_GET_JOB, viewApplyJob: AC_VIEW_APPLY_JOB, EnableSpinner: AC_SPINNER_START }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ApplyJob);