const LIVE = true;
const LIVEURL = LIVE ? "https://admin.myhotelai.com/" : 'http://localhost:4001/';
const LOCALURL = LIVE ? "https://admin.myhotelai.com/" : 'http://localhost:3000/';
const FILEURL = "https://assets-myhotelai.s3.us-east-2.amazonaws.com/";
// const FILEURL = LIVE ? 'https://assets-myhotelai.s3.us-east-2.amazonaws.com/' : "https://test-assets-myhotelai.s3.us-east-2.amazonaws.com/";

const ROOTURL = LIVEURL + 'api/v1/';
const API = {
  listHotel: ROOTURL + 'hotel/acwCarrerHotel',
  getJob: ROOTURL + 'job/getAllJob',
  applyJob: ROOTURL + 'jobapplication/applyJob',
  listJobApplication: ROOTURL + 'jobapplication/listJobApplication',
  viewApplyJob: ROOTURL + 'job/getJobInfo',
}

const ImportedURL = {
  API: API,
  LIVEURL: LIVEURL,
  LOCALURL: LOCALURL,
  FILEURL: FILEURL,
}
export default ImportedURL;
