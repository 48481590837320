import React, { Component } from 'react'
import { connect } from 'react-redux';
import moment from 'moment';
import { Container, Form, Row } from 'react-bootstrap';
import { HiLocationMarker } from "react-icons/hi";
import { bindActionCreators } from 'redux';
import { Link, Redirect } from 'react-router-dom';
import Autocomplete from 'react-google-autocomplete';
import '../../assets/css/job_apply_form.css';
import Logo from './logo.png';
import URL from '../../common/api';
import NODATA from './no-data.png';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import { AC_GET_JOB, AC_SPINNER_START } from '../../actions/jobAction';
import { onErrorImage } from '../../common/validate';
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';

const initialState = {
    jobId: '',
    industry: 'all',
    keyword: '',
    city: '',
    lat: '',
    lon: '',
    km: '',
    searchhoteloption: { label: 'All', value: '', name: 'industry' },
}

class Jobs extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.props.ListHotel();
        let formData = {
            hotel: this.state.industry,
            keyword: this.state.keyword,
            city: this.state.city,
            lat: this.state.lat,
            lon: this.state.lon,
            km: this.state.km
        };
        this.props.EnableSpinner()
        this.props.GetJob(formData);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }

    applyJob = value => {
        this.setState({ jobId: value })
    }

    searchJob = () => {
        let formData = {
            hotel: this.state.industry,
            keyword: this.state.keyword,
            city: this.state.city,
            lat: this.state.lat,
            lon: this.state.lon,
            km: this.state.km
        };
        this.props.EnableSpinner()
        this.props.GetJob(formData);
    }

    setValue(e) {
        this.setState({ city: e.target.value })
    }

    onChangeHotel = e => {
        const { name, value, label } = e;
        if (name === 'industry') {
            this.setState({ industry: value, searchhoteloption: e })
            let formData = {
                hotel: value,
                keyword: this.state.keyword,
                city: this.state.city,
                lat: this.state.lat,
                lon: this.state.lon,
                km: this.state.km
            };
            this.props.EnableSpinner()
            this.props.GetJob(formData);
        }
    }

    onChangeCompany = event => {
        let name = event.target.name;
        let value = event.target.value;
        if (name === 'keyword') {
            this.setState({ keyword: value })
        }
        if (name === 'city') {
            this.setState({ city: value })
        }
        if (name === 'km') {
            this.setState({ km: value })
        }
    }

    render() {
        if (this.state.jobId) {
            return <Redirect to={'/apply-job/' + this.state.jobId} />
        }
        const { HotelState, JobState } = this.props;
        const listHotels = HotelState.listhotels;
        const listJobs = JobState.jobList;
        const spinner = JobState.spinner;
        // let hotelList = listHotels.length > 0
        //     && listHotels.map((item, i) => {
        //         return (
        //             <option key={i} value={item._id}>{item.name}</option>
        //         )
        //     }, this);
        let hotelOptions = []
        if (listHotels && listHotels.length > 1) {
            hotelOptions.push({ label: 'All', value: '', name: 'industry' })
        }
        listHotels && listHotels.length > 0 && listHotels.filter(filterItem => filterItem.status === true).map((item) => {
            hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'industry' });
        })

        let jobList = listJobs && listJobs.length > 0
            && listJobs.map((item, i) => {
                return (
                    <div className='apply_sec career' key={i}>
                        <Row>
                            <div className='col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'>
                                <div className='title_loc'>
                                    <h4>{item.position ? item.position.name : ''}</h4>
                                    <span className='name'>{item.hotel}</span>
                                    <p><HiLocationMarker className='loc_i' />{item.address}</p>
                                </div>
                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'>
                                <div className='cnpd'>
                                    <Row>
                                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                                            <div className='np'>
                                                <span className='pd'>Posted On:{item.posedDate}</span>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </Row>
                        <div className='app_com'>
                            <Row>
                                <div className='col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 '>
                                    <div className='cpinfo' >
                                        <div className='career_description' dangerouslySetInnerHTML={{ __html: item.description + '...' }}></div>
                                        <a href={item.site} className='site_link' target="_blank">{item.site}</a>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 '>
                                    <div className='company_logo'>
                                        <div className='logo_size'>
                                            <img src={URL.FILEURL + item.companyLogo} alt='' onError={onErrorImage} />
                                        </div>
                                        <div className='apply_btn'>
                                            <button type='button' className='btn' onClick={() => this.applyJob(item.jobId)}><i class="fa fa-check mr-2" aria-hidden="true"></i>Apply</button>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                )
            }, this);
        return (
            <>
                <div>
                    <section className='job_apply_form'>
                        <Container>
                            <div className='top_brand_log'>
                                <div className='brand_img_size'>
                                    <img src={Logo} alt='' />
                                </div>
                            </div>
                            <div className='top_content_acw'>
                                <h5 className='title'>Acw Hospitality</h5>
                                <p className='phara'>Various openings can be placed for any location on ACW Careers. This quality makes it easier for job seekers to select a specific profession. Online job boards can also help businesses speed up the hiring process. Hiring managers or recruiters might quickly validate job applications online rather than manually scanning paper resumes.

                                    It is designed to help hotels connect with a pool of interested candidates when they post any positions. It can make a huge difference in almost every employment procedure when implemented properly.</p>
                            </div>
                            <div className='job_serch_form'>
                                <h5 className='form_title'>Current job openings :</h5>
                                <Form>
                                    <Row>
                                        <div className='col-xl-4 col-lg-4 col-md-6 col-col-sm-12 col-12 px-1'>
                                            <div className='feilds-1'>
                                                <Select
                                                    value={this.state.searchhoteloption}
                                                    onChange={(e) => this.onChangeHotel(e)}
                                                    options={hotelOptions}
                                                    formatOptionLabel={(e) => (
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            {e.label != 'All' && <img className='all_search_image_size contain_image' src={URL.FILEURL + e.logo} onError={onErrorImage} />}
                                                            <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-xl-3 col-lg-3 col-md-6 col-col-sm-12 col-12 px-1'>
                                            <div className='feilds'>
                                                <Form.Control className="keyword_box" name="keyword" type="text" placeholder="Enter Keywords" onChange={(e) => this.onChangeCompany(e)} />
                                            </div>
                                        </div>
                                        <div className='col-xl-3 col-lg-3  col-md-6 col-col-sm-12 col-12 px-1'>
                                            <div className='feilds-1'>
                                                <Form.Control className="keyword_box" name="city" type="city" placeholder="Enter city" onChange={(e) => this.onChangeCompany(e)} />
                                            </div>
                                        </div>
                                        <div className='col-xl-2 col-lg-2 col-md-6 col-col-sm-12 col-12 px-1'>
                                            <div className='s_button'>
                                                <button type='button' onClick={this.searchJob}><i class="fa fa-search mr-2" aria-hidden="true"></i> search</button>
                                            </div>
                                        </div>
                                    </Row>
                                </Form>
                            </div>
                            {jobList ? jobList :
                                <div>
                                    <img src={NODATA} />
                                </div>
                            }
                            <div className='bd_design'></div>
                            <div className='bottom_brand_log'>
                                <div className='brand_img_size'>
                                    <img src={Logo} alt='' />
                                </div>
                            </div>
                        </Container>
                    </section>
                    <div id="overlay" style={{ display: (spinner || this.state.spinner) ? 'block' : 'none' }}></div>
                    {(spinner || this.state.spinner) ?
                        <div className='common_loader_ag_grid'>
                            <img className='loader_img_style_common_ag_grid' src='../../assets/images/careerlogo.png' onError={onErrorImage} />
                            <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                            </Spinner>
                        </div>
                        : ""}
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    HotelState: state.hotel,
    JobState: state.job
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ListHotel: AC_LIST_HOTEL, GetJob: AC_GET_JOB, EnableSpinner: AC_SPINNER_START }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Jobs);