import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_HANDLE_JOB_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_JOB_CHANGE", name: name, value: value })
	};
}

export function AC_RESET_JOB() {
	return function (dispatch) {
		dispatch({ type: "RESET_JOB" })
	};
}

export function AC_ADD_JOB(formData) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.addJob, formData)
			.then((res) => {
				Success(res.statusText);
				dispatch({ type: "LIST_JOB", payload: res.data })
				dispatch({ type: "RESET_JOB" })
			}).catch((err) => { if (err.response) Error(err.response.statusText) });
	};
}

export function AC_LIST_JOB() {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listJob)
			.then((res) => {
				dispatch({ type: "LIST_JOB", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_VIEW_JOB(id) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.viewJob + "/" + id)
			.then((res) => {
				dispatch({ type: "VIEW_JOB", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_SHARE_JOB(id) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.shareJob + "/" + id)
			.then((res) => {
				dispatch({ type: "SHARE_JOB", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_UPDATE_JOB(formData, id) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.updateJob + "/" + id, formData)
			.then((res) => {
				Success(res.statusText);
				dispatch({ type: "LIST_JOB", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_DELETE_JOB(id) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.deleteJob + "/" + id)
			.then((res) => {
				Success(res.statusText);
				dispatch({ type: "LIST_JOB", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_GET_JOB(data) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.getJob, data)
			.then((res) => {
				dispatch({ type: "GET_JOB", payload: res.data, spinner: false })
			}).catch((err) => {
				console.log(err);
				dispatch({ type: "GET_JOB", spinner: false })
			});
	};
}
export function AC_SPINNER_START() {
	return function (dispatch) {
		dispatch({ type: "SPINNER_START" })
	};
}
export function AC_VIEW_APPLY_JOB(id) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.viewApplyJob + "/" + id)
			.then((res) => {
				dispatch({ type: "VIEW_APPLY_JOB", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}