const jobData = {
	hotel: '',
	jobtitle: '',
	description: '',
	salary: '',
	openings: '',
	location: '',
	address: '',
	experience: '',
	status: 1,
}
const applyJobData = {
	jobtitle: '',
	description: '',
	salary: '',
	experience: '',
	status: 1,
}
const initialState = {
	job: jobData,
	applyJobData: applyJobData,
	listjobs: [],
	jobList: [],
	sharejob: {},
	spinner: true
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'HANDLE_JOB_CHANGE':
			return Object.assign({}, state, {
				job: {
					...state.job,
					[action.name]: action.value
				}
			})
		case 'RESET_JOB':
			return Object.assign({}, state, {
				job: jobData
			})
		case 'LIST_JOB':
			return Object.assign({}, state, {
				listjobs: action.payload
			})
		case 'VIEW_JOB':
			return Object.assign({}, state, {
				job: action.payload
			})
		case 'VIEW_APPLY_JOB':
			return Object.assign({}, state, {
				applyJobData: action.payload,
				spinner: false
			})
		case 'SHARE_JOB':
			return Object.assign({}, state, {
				sharejob: action.payload
			})
		case 'GET_JOB':
			return Object.assign({}, state, {
				jobList: action.payload,
				spinner: action.spinner
			})
		case 'SPINNER_START':
			return Object.assign({}, state, {
				spinner: true
			})
		default:
			return state;
	}
}