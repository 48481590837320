import axios from 'axios';
import ImportedURL from '../common/api';

export function AC_LIST_HOTEL() {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listHotel)
			.then((res) => {
				dispatch({ type: "LIST_HOTEL", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}


